import React from "react";
import Typography from "@mui/material/Typography";
import { menuImages, restaurantImages } from "./gallery";
import { getSwiperImages } from "../../utils/swiper";
import {
  getMerchantAccolades,
  ACCOLADE_FOOD_HYGIENE,
} from "../../utils/merchant";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Ashoka, an Indian restaurant on Holywell Street, Chesterfield was built
        in a mock tudor style during the 1930s. Newly refurbished restaurant
        offering a tranquil and exhilarating atmosphere with a unique dining
        experience.
      </Typography>
      <Typography variant="body1" paragraph>
        Our extensive choice from a-la-carte menu caters to all tastes from
        vegetarian to special healthy diets. If you are seeking for traditional
        Indian food of its vast cultures and colours, at Ashoka our sole purpose
        is to ensure that you are served and are regaled by a soothing yet
        refreshing ambience.
      </Typography>
      <Typography variant="body1" paragraph>
        We are the only Indian Restaurant in Chesterfield to offer our customers
        separate space for private parties, anniversaries, birthdays or to host
        a corporate and charity event and FREE Wi-Fi for all guests.
      </Typography>
      <Typography variant="body1" paragraph>
        We offer many vegan options, and can make a vegan version of most of our
        dishes. Please ask Malik whilst you are at our restaurant, or for online
        orders, please write in the comments section when ordering if you want a
        particular dish made vegan.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return getMerchantAccolades([ACCOLADE_FOOD_HYGIENE]);
}

export function getGalleryMenuImages() {
  return getSwiperImages(menuImages);
}

export function getGalleryRestaurantImages() {
  return getSwiperImages(restaurantImages);
}
