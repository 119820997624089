export const menuImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/4.jpg",
    alt: "4",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/5.jpg",
    alt: "5",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/6.jpg",
    alt: "6",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/2.jpg",
    alt: "2",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/3.jpg",
    alt: "3",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/menu/1.jpg",
    alt: "1",
  },
];

export const restaurantImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/9.jpg",
    alt: "9",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/4.jpg",
    alt: "4",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/5.jpg",
    alt: "5",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/7.jpg",
    alt: "7",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/6.jpg",
    alt: "6",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/2.jpg",
    alt: "2",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/3.jpg",
    alt: "3",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/1.jpg",
    alt: "1",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/ashoka/restaurant/0.jpg",
    alt: "0",
  },
];
