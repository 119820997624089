const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#b01f24", hover: "#DE4348" },
    secondary: { main: "#3ddc2b", hover: "#7DE871" },
    accent: "#b01f24",
    info: {
      main: "#b01f24",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
